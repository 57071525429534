"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const gqlActions_1 = require("../gqlActions");
function useGetMeals(whens, dependantDataReady = true) {
    //Inspects the cache for meal items with the specified ids, does call for ids not present.
    const client = (0, client_1.useApolloClient)();
    const [mealsFromCache, whensNotInCache] = (0, react_1.useMemo)(() => {
        if (dependantDataReady) {
            const mealsFromCache = whens.map((when) => client.readFragment({
                id: client.cache.identify({ __typename: "Meal", when }) || "IDONTEXIST",
                fragment: (0, client_1.gql) `
            fragment M on Meal {
              when
              comment
              dishes {
                dishId
                portions
              }
              ingredients {
                amount
                foodId
              }
            }
          `,
            }));
            return [
                mealsFromCache.filter((x) => x),
                whens.filter((_, i) => !mealsFromCache[i]),
            ];
        }
        else {
            return [[], whens];
        }
    }, [whens, dependantDataReady]);
    const { data, loading: loadingQuery } = (0, client_1.useQuery)((0, client_1.gql)(gqlActions_1.ACTION_GET_MEALS), {
        skip: !dependantDataReady ||
            whensNotInCache.length === 0 ||
            whens.filter((x) => x).length === 0,
        variables: {
            whens: whensNotInCache,
        },
    });
    return [
        [...mealsFromCache, ...((data === null || data === void 0 ? void 0 : data.getMeals) || [])],
        { loading: !dependantDataReady || loadingQuery },
    ];
}
exports.default = useGetMeals;
