"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useTitle() {
    return {
        "/": "Diario de calorías",
        "/comidas": "Registro de comidas",
        "/alimentos": "Alimentos",
        "/comparador": "Comparador de alimentos",
        "/recetas": "Recetas",
    }[window.location.pathname];
}
exports.default = useTitle;
