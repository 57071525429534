"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserSub = exports.GetUserSubResponse = exports.refreshAccessToken = exports.RefreshAccessTokenResponse = exports.signIn = exports.SignInResponse = exports.confirmSignup = exports.ConfirmSignUpResponse = exports.signUp = exports.SignUpResponse = void 0;
const client_cognito_identity_provider_1 = require("@aws-sdk/client-cognito-identity-provider");
const { UserPoolClientID, Region } = require("../../outputs.json");
const client = new client_cognito_identity_provider_1.CognitoIdentityProviderClient({ region: Region });
var SignUpResponse;
(function (SignUpResponse) {
    SignUpResponse["PasswordInvalid"] = "PasswordInvalid";
    SignUpResponse["UserAlreadyExists"] = "UserAlreadyExists";
    SignUpResponse["CodeSentToEmail"] = "CodeSentToEmail";
    SignUpResponse["UnexpectedResponse"] = "UnexpectedResponse";
})(SignUpResponse = exports.SignUpResponse || (exports.SignUpResponse = {}));
const signUp = (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const response = yield client.send(new client_cognito_identity_provider_1.SignUpCommand({
            ClientId: UserPoolClientID,
            Password: password,
            Username: username,
        }));
        if (!response.UserConfirmed &&
            ((_a = response.CodeDeliveryDetails) === null || _a === void 0 ? void 0 : _a.DeliveryMedium) === "EMAIL") {
            return SignUpResponse.CodeSentToEmail;
        }
        else {
            return SignUpResponse.UnexpectedResponse;
        }
    }
    catch (e) {
        const error = e;
        switch (error.name) {
            case "UsernameExistsException":
                return SignUpResponse.UserAlreadyExists;
            case "InvalidPasswordException":
            case "InvalidParameterException":
                return SignUpResponse.PasswordInvalid;
        }
        throw e;
    }
});
exports.signUp = signUp;
var ConfirmSignUpResponse;
(function (ConfirmSignUpResponse) {
    ConfirmSignUpResponse["Confirmed"] = "Confirmed";
    ConfirmSignUpResponse["InvalidCode"] = "InvalidCode";
})(ConfirmSignUpResponse = exports.ConfirmSignUpResponse || (exports.ConfirmSignUpResponse = {}));
const confirmSignup = (username, code) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield client.send(new client_cognito_identity_provider_1.ConfirmSignUpCommand({
            ClientId: UserPoolClientID,
            ConfirmationCode: code,
            Username: username,
        }));
        return ConfirmSignUpResponse.Confirmed;
    }
    catch (e) {
        const error = e;
        switch (error.name) {
            case "CodeMismatchException":
            case "ExpiredCodeException":
                return ConfirmSignUpResponse.InvalidCode;
        }
        throw e;
    }
});
exports.confirmSignup = confirmSignup;
var SignInResponse;
(function (SignInResponse) {
    SignInResponse["SignedIn"] = "SignedIn";
    SignInResponse["InvalidPassword"] = "SignedIn";
})(SignInResponse = exports.SignInResponse || (exports.SignInResponse = {}));
const signIn = (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield client.send(new client_cognito_identity_provider_1.InitiateAuthCommand({
            ClientId: UserPoolClientID,
            AuthFlow: "USER_PASSWORD_AUTH",
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        }));
        console.log(response);
        updateSession(response);
        return SignInResponse.SignedIn;
    }
    catch (e) {
        const error = e;
        switch (error.name) {
            case "NotAuthorizedException":
                return SignInResponse.InvalidPassword;
        }
        throw e;
    }
});
exports.signIn = signIn;
var RefreshAccessTokenResponse;
(function (RefreshAccessTokenResponse) {
    RefreshAccessTokenResponse["InvalidToken"] = "InvalidToken";
    RefreshAccessTokenResponse["Refreshed"] = "Refreshed";
})(RefreshAccessTokenResponse = exports.RefreshAccessTokenResponse || (exports.RefreshAccessTokenResponse = {}));
const refreshAccessToken = (refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield client.send(new client_cognito_identity_provider_1.InitiateAuthCommand({
            ClientId: UserPoolClientID,
            AuthFlow: "REFRESH_TOKEN_AUTH",
            AuthParameters: {
                REFRESH_TOKEN: refreshToken,
            },
        }));
        console.log(response);
        updateSession(response);
        return RefreshAccessTokenResponse.Refreshed;
    }
    catch (e) {
        const error = e;
        switch (error.name) {
            case "NotAuthorizedException":
            case "ExpiredTokenException":
                return RefreshAccessTokenResponse.InvalidToken;
        }
        throw e;
    }
});
exports.refreshAccessToken = refreshAccessToken;
var GetUserSubResponse;
(function (GetUserSubResponse) {
    GetUserSubResponse["InvalidToken"] = "InvalidToken";
})(GetUserSubResponse = exports.GetUserSubResponse || (exports.GetUserSubResponse = {}));
const getUserSub = (accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    try {
        const response = yield client.send(new client_cognito_identity_provider_1.GetUserCommand({
            AccessToken: accessToken,
        }));
        return (_c = (_b = response.UserAttributes) === null || _b === void 0 ? void 0 : _b.find(({ Name }) => Name === "sub")) === null || _c === void 0 ? void 0 : _c.Value;
    }
    catch (e) {
        const error = e;
        switch (error.name) {
            case "NotAuthorizedException":
            case "ExpiredTokenException":
                return GetUserSubResponse.InvalidToken;
        }
        throw e;
    }
});
exports.getUserSub = getUserSub;
const updateSession = (signInResponse) => {
    const { AuthenticationResult } = signInResponse;
    if (AuthenticationResult === null || AuthenticationResult === void 0 ? void 0 : AuthenticationResult.RefreshToken) {
        localStorage.setItem("refreshToken", AuthenticationResult === null || AuthenticationResult === void 0 ? void 0 : AuthenticationResult.RefreshToken);
    }
    if (AuthenticationResult === null || AuthenticationResult === void 0 ? void 0 : AuthenticationResult.AccessToken) {
        sessionStorage.setItem("accessToken", AuthenticationResult === null || AuthenticationResult === void 0 ? void 0 : AuthenticationResult.AccessToken);
    }
};
