"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const types_1 = require("../lib/types");
const chart_js_1 = require("chart.js");
chart_js_1.Chart.register(chart_js_1.LinearScale, chart_js_1.LineController, chart_js_1.CategoryScale, chart_js_1.PointElement, chart_js_1.LineElement, chart_js_1.plugins.Tooltip, chart_js_1.plugins.Filler);
function Graphic({ macrosByDay, }) {
    const chartElement = (0, react_1.useRef)(null);
    const chartObjectRef = (0, react_1.useRef)();
    const daysWithData = Object.keys(macrosByDay);
    const lastDay = daysWithData[0];
    const firstDay = daysWithData[daysWithData.length - 1];
    const days = [];
    let date = new Date(firstDay);
    while (days[days.length - 1] !== lastDay) {
        days.push(date.toISOString().slice(0, 10));
        date.setDate(date.getDate() + 1);
        if (date.getFullYear() > 2040)
            break;
    }
    (0, react_1.useEffect)(() => {
        console.log("hago render vacio");
        chartObjectRef.current = new chart_js_1.Chart(chartElement.current, {
            type: "line",
            data: {
                datasets: [],
            },
            options: {
                scales: {
                    y: {
                        type: "linear",
                        position: "right",
                        grid: {
                            tickColor: types_1.MACRO_COLORS.cals,
                            tickWidth: 2,
                            drawOnChartArea: false,
                        },
                        ticks: {
                            color: types_1.MACRO_COLORS.cals,
                            font: {
                                weight: "bold",
                            },
                            callback: (val) => `${val} kcal`,
                        },
                    },
                    y1: {
                        type: "linear",
                        grid: {
                            drawOnChartArea: false,
                        },
                        ticks: {
                            callback: (val) => `${val}g`,
                        },
                    },
                    x: {
                        grid: { display: false },
                    },
                },
                datasets: {
                    line: {
                        borderWidth: 2,
                    },
                },
                elements: {
                    point: {
                        pointStyle: false,
                    },
                    line: {
                        spanGaps: false,
                        borderJoinStyle: "round",
                    },
                },
                plugins: {
                    tooltip: {
                        mode: "nearest",
                        intersect: false,
                        displayColors: false,
                    },
                },
            },
        });
        return () => {
            if (chartObjectRef.current)
                chartObjectRef.current.destroy();
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const updateIfChartInstancePresent = () => {
            if (chartObjectRef.current) {
                chartObjectRef.current.data.datasets = [
                    {
                        data: days.map((day) => ({ x: day })),
                    },
                ].concat(types_1.MACROS.map((macro, index) => ({
                    yAxisID: macro === types_1.MACROS[0] ? "y" : "y1",
                    label: types_1.MACRO_LABELS[macro],
                    data: Object.entries(macrosByDay).map(([day, macros]) => ({
                        x: day,
                        y: macros[macro],
                    })),
                    borderColor: `${types_1.MACRO_COLORS[macro]}${macro === types_1.MACROS[0] ? "FF" : "50"}`,
                    fill: macro === types_1.MACROS[0] ? "origin" : "false",
                    backgroundColor: `${types_1.MACRO_COLORS[macro]}30`,
                    order: macro === types_1.MACROS[0] ? types_1.MACROS.length : index,
                })));
                chartObjectRef.current.update();
            }
            else {
                requestAnimationFrame(updateIfChartInstancePresent);
            }
        };
        updateIfChartInstancePresent();
    }, [JSON.stringify(macrosByDay)]);
    return react_1.default.createElement("canvas", { ref: chartElement });
}
exports.default = Graphic;
