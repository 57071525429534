"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTION_DELETE_MEAL = exports.ACTION_DELETE_DISH = exports.ACTION_DELETE_FOOD = exports.ACTION_EDIT_MEAL = exports.ACTION_EDIT_DISH = exports.ACTION_CREATE_DISH = exports.ACTION_CREATE_MEAL = exports.ACTION_LIST_MEALS = exports.ACTION_LIST_DISHES = exports.ACTION_GET_DISHES = exports.ACTION_GET_MEALS = exports.ACTION_EDIT_FOOD = exports.ACTION_CREATE_FOOD = exports.ACTION_LIST_FOODS = exports.ACTION_GET_FOODS = void 0;
exports.ACTION_GET_FOODS = `
  query GetFoods($ids: [ID]!) {
    getFoods(ids: $ids) {
      id
      name
      description
      macros {
        unit
        cals
        proteins
        carbs
        sugars
        fats
        satfats
        salt
      }
    }
  }
`;
exports.ACTION_LIST_FOODS = `
  query ListFoods {
    listFoods {
      items {
        id
        name
        description
        macros {
          unit
          cals
          proteins
          carbs
          sugars
          fats
          satfats
          salt
        }
      }
    }
  }
`;
exports.ACTION_CREATE_FOOD = `
  mutation AddFoods($input: AddFoodInput!) {
    addFood(input: $input) {
      id
      name
      description
      macros {
        unit
        cals
        proteins
        carbs
        sugars
        fats
        satfats
        salt
      }
    }
  }
`;
exports.ACTION_EDIT_FOOD = `
  mutation EditFood($input: EditFoodInput!) {
    editFood(input: $input) {
      id
      name
      description
      macros {
        unit
        cals
        proteins
        carbs
        sugars
        fats
        satfats
        salt
      }
    }
  }
`;
exports.ACTION_GET_MEALS = `
  query GetMeals($whens: [AWSDateTime]!) {
    getMeals(whens: $whens) {
      when
      comment
      dishes {
        dishId
        portions
      }
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_GET_DISHES = `
  query GetDishes($ids: [ID]!) {
    getDishes(ids: $ids) {
      id
      name
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_LIST_DISHES = `
  query ListDishes {
    listDishes {
      items {
        id
        name
        ingredients {
          amount
          foodId
        }
      }
    }
  }
`;
exports.ACTION_LIST_MEALS = `
  query ListMeals($before: AWSDateTime, $after: AWSDateTime) {
    listMeals(before: $before, after: $after) {
      items {
        when
        comment
        dishes {
          dishId
          portions
        }
        ingredients {
          amount
          foodId
        }
      }
    }
  }
`;
exports.ACTION_CREATE_MEAL = `
  mutation AddMeal($input: AddMealInput!) {
    addMeal(input: $input) {
      when
      comment
      dishes {
        dishId
        portions
      }
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_CREATE_DISH = `
  mutation AddDish($input: AddDishInput!) {
    addDish(input: $input) {
      id
      name
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_EDIT_DISH = `
  mutation EditDish($input: EditDishInput!) {
    editDish(input: $input) {
      id
      name
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_EDIT_MEAL = `
  mutation EditMeal($input: EditMealInput!) {
    editMeal(input: $input) {
      when
      comment
      dishes {
        dishId
        portions
      }
      ingredients {
        amount
        foodId
      }
    }
  }
`;
exports.ACTION_DELETE_FOOD = `
  mutation DeleteFood($id: ID!) {
    deleteFood(id: $id)
  }
`;
exports.ACTION_DELETE_DISH = `
  mutation DeleteDish($id: ID!) {
    deleteDish(id: $id)
  }
`;
exports.ACTION_DELETE_MEAL = `
  mutation DeleteMeal($when: AWSDateTime!) {
    deleteMeal(when: $when)
  }
`;
