"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const gqlActions_1 = require("../gqlActions");
function useGetDishes(ids, dependantDataReady = true) {
    //Inspects the cache for food items with the specified ids, does call for ids not present.
    const client = (0, client_1.useApolloClient)();
    const [dishesFromCache, idsNotInCache] = (0, react_1.useMemo)(() => {
        if (dependantDataReady) {
            const dishesFromCache = ids.map((id) => client.readFragment({
                id: `Dish:${id}`,
                fragment: (0, client_1.gql) `
						fragment D on Dish {
							id
							name
							ingredients {
								amount
								foodId
							}
						}
					`,
            }));
            return [
                dishesFromCache.filter((x) => x),
                ids.filter((id, i) => !dishesFromCache[i]),
            ];
        }
        else {
            return [[], ids];
        }
    }, [ids, dependantDataReady]);
    const { data, loading: loadingQuery } = (0, client_1.useQuery)((0, client_1.gql)(gqlActions_1.ACTION_GET_DISHES), {
        skip: !dependantDataReady || idsNotInCache.filter((x) => x).length === 0,
        variables: { ids: idsNotInCache },
    });
    return [
        [...dishesFromCache, ...((data === null || data === void 0 ? void 0 : data.getDishes) || [])],
        { loading: !dependantDataReady || loadingQuery },
    ];
}
exports.default = useGetDishes;
