"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const gqlActions_1 = require("../gqlActions");
function useGetFoods(ids, dependantDataReady = true) {
    //Inspects the cache for food items with the specified ids, does call for ids not present.
    const client = (0, client_1.useApolloClient)();
    const [foodsFromCache, idsNotInCache] = (0, react_1.useMemo)(() => {
        if (dependantDataReady) {
            const foodsFromCache = ids.map((id) => client.readFragment({
                id: `Food:${id}`,
                fragment: (0, client_1.gql) `
            fragment EverythingUseful on Food {
              id
              name
              description
              macros {
                unit
                cals
                proteins
                carbs
                sugars
                fats
                satfats
                salt
              }
            }
          `,
            }));
            return [
                foodsFromCache.filter((x) => x),
                ids.filter((id, i) => !foodsFromCache[i]),
            ];
        }
        else {
            return [[], ids];
        }
    }, [ids, dependantDataReady]);
    const { data, loading: loadingQuery } = (0, client_1.useQuery)((0, client_1.gql)(gqlActions_1.ACTION_GET_FOODS), {
        skip: !dependantDataReady || idsNotInCache.length === 0,
        variables: { ids: idsNotInCache },
    });
    return [
        [...foodsFromCache, ...((data === null || data === void 0 ? void 0 : data.getFoods) || [])],
        { loading: !dependantDataReady || loadingQuery },
    ];
}
exports.default = useGetFoods;
