"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const withAuth_1 = require("./withAuth");
const { GraphQLUrl } = require("../../outputs.json");
exports.default = (Component) => () => {
    console.log("reRendering withApollo");
    const { token } = (0, react_1.useContext)(withAuth_1.AuthContext);
    const link = client_1.ApolloLink.from([
        new client_1.ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    Authorization: token,
                },
            });
            return forward(operation);
        }),
        new client_1.HttpLink({
            uri: GraphQLUrl,
        }),
    ]);
    return (react_1.default.createElement(client_1.ApolloProvider, { client: new client_1.ApolloClient({
            link,
            cache: new client_1.InMemoryCache({
                typePolicies: { Meal: { keyFields: ["when"] } },
            }),
        }) },
        react_1.default.createElement(Component, null)));
};
